import { theme } from '@chakra-ui/pro-theme';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import App from './App';
import ErrorPage from './ErrorPage';
import About from './components/About';
import Gallery from './components/Gallery';
import Login from './components/Login';
import Settings, { SettingsEdit, SettingsIndex } from './components/Settings';
import UserPage from './components/UserPage';
import './index.css';
import reportWebVitals from './reportWebVitals';

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const proTheme = extendTheme(theme);
const extenstion = {
    colors: { ...proTheme.colors, brand: proTheme.colors.teal },
};
const myTheme = extendTheme(extenstion, proTheme);

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            { path: '/about', element: <About /> },
            { path: '/gallery', element: <Gallery /> },
            { path: '/user', element: <UserPage /> },
            {
                path: '/settings',
                element: <Settings />,
                children: [
                    {
                        index: true,
                        element: <SettingsIndex />,
                    },
                    {
                        path: '/settings/:field',
                        element: <SettingsEdit />,
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        element: <Login />,
    },
]);

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={myTheme}>
                <RouterProvider router={router} />
            </ChakraProvider>
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
