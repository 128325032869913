import {
    Button,
    Center,
    Container,
    HStack,
    Link,
    Spinner,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';

import { GoogleIcon } from './ProviderIcons';

export default function Login() {
    const auth = getAuth();
    const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);
    const toast = useToast();

    onAuthStateChanged(auth, (user) => {
        if (user) window.location.href = '/';
    });

    useEffect(() => {
        if (error)
            toast({
                title: '登入失敗',
                description: '請重新嘗試',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
    }, [error, toast]);

    if (loading || user)
        return (
            <Center flex="1">
                <Spinner size="xl" />
            </Center>
        );
    return (
        <Container maxW="md" py={{ base: '12', md: '24' }}>
            <Stack spacing="8">
                <Stack spacing="6" alignItems="center">
                    <Link
                        href="/"
                        className="logo bg-pc1 h-10 w-10"
                        type="button"
                    />
                    {/* <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                        <Heading size={{ base: 'xs', md: 'sm' }}>
                            輸入你的電郵
                        </Heading>
                        <Text color="fg.muted"></Text>
                    </Stack> */}
                </Stack>
                <Stack spacing="6">
                    {/* <Stack spacing="4">
                        <Input placeholder="電郵" type="email" />
                        <Button>登入或注冊</Button>
                    </Stack>
                    <HStack>
                        <Divider />
                        <Text textStyle="sm" color="fg.muted">
                            或
                        </Text>
                        <Divider />
                    </HStack> */}
                    <Stack spacing="3">
                        <Button
                            variant="secondary"
                            leftIcon={<GoogleIcon />}
                            onClick={() => signInWithGoogle()}
                        >
                            使用Google賬號
                        </Button>
                    </Stack>
                </Stack>
                <HStack spacing="1" justify="center">
                    <Text textStyle="sm" color="fg.muted">
                        登入問題? <Link href="#">聯絡我們</Link>
                    </Text>
                </HStack>
            </Stack>
        </Container>
    );
}
