import { Link } from 'react-router-dom';

import { NavBar } from './NavBar';
import { NavBarCollapsed } from './NavBarCollapsed';

export default function AppHeader() {
    return (
        <header className="flex bg-white h-20 items-center shadow-md gap-5 px-10 py-5 sm:px-10 sm:py-2">
            <Link to="/" className="logo bg-pc1 h-10 w-10" type="button" />
            <div className="hidden md:flex md:grow gap-5">
                <NavBar />
            </div>
            <div className="md:hidden flex flex-row-reverse grow">
                <NavBarCollapsed />
            </div>
        </header>
    );
}
