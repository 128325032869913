import { ArrowBackIcon } from '@chakra-ui/icons';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Spinner,
    VisuallyHidden,
    useToast,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { User, getAuth } from 'firebase/auth';
import {
    collection,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import 'react-firebase-hooks/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { queryClient } from '../..';
import { db } from '../../firebase';
import { translationMap, translationMapType } from './utils';

export function SettingsEdit() {
    const { field } = useParams<'field'>();

    return (
        <Card className="w-screen sm:max-w-screen-sm">
            <CardHeader className="flex items-center gap-8">
                <Link to="/settings">
                    <ArrowBackIcon w={8} h={8} />
                </Link>
                <h3 className="capitalize">
                    {field && field in translationMap
                        ? translationMap[field as keyof translationMapType]
                        : field}
                </h3>
            </CardHeader>
            <CardBody>
                {field === 'name' ? (
                    <SettingsNameEdit />
                ) : field === 'username' ? (
                    <SettingsUsernameEdit />
                ) : (
                    <>{field}</>
                )}
            </CardBody>
        </Card>
    );
}

function SubmitButton({ isDisabled }: { isDisabled?: boolean }) {
    return (
        <Button
            type="submit"
            className="w-full sm:w-32"
            isDisabled={isDisabled}
        >
            確定
        </Button>
    );
}

function SettingsNameEditContent({ user }: { user: User }) {
    const [input, setInput] = useState('');
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setInput(e.target.value);
    const userRef = doc(db, 'Users', user.uid);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await updateDoc(userRef, {
            displayName: input,
        });
    };
    const navigate = useNavigate();
    const { mutate, isPending, isError } = useMutation({
        mutationFn: onSubmit,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['userData'] });
            navigate('/settings');
        },
    });

    return (
        <form onSubmit={(e) => mutate(e)}>
            <FormControl className="flex flex-col gap-4 items-center">
                <VisuallyHidden>
                    <FormLabel>{translationMap.name}</FormLabel>
                </VisuallyHidden>
                <div className="w-full">
                    <Input
                        type="text"
                        value={input}
                        onChange={onChange}
                        placeholder={translationMap.name}
                        isDisabled={isPending}
                    />
                    <FormHelperText>你的公開名稱</FormHelperText>
                    {isError && <span>網絡錯誤，請重新發送</span>}
                </div>
                <SubmitButton isDisabled={!userRef || !input || isPending} />
            </FormControl>
        </form>
    );
}

function SettingsNameEdit() {
    const auth = getAuth();
    const [user] = useAuthState(auth);
    if (!user) return <Spinner />;
    return <SettingsNameEditContent user={user} />;
}

function SettingsUsernameEditContent({ user }: { user: User }) {
    const toast = useToast();
    const [userData, isUserDataLoading] = useDocumentData(
        doc(db, 'Users', user.uid)
    );

    const [input, setInput] = useState('');
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setInput(e.target.value);

    const alphanumeric = /[\w-]{6,30}/gu;
    const match = input.match(alphanumeric);
    const isInvalid = match === null || match[0] !== input;

    const userRef = doc(db, 'Users', user.uid);
    const usernameQuery = query(
        collection(db, 'Users'),
        where('username', '==', input)
    );

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // check if username exists
        if ((await getDocs(usernameQuery)).empty) {
            // update username
            await updateDoc(userRef, {
                username: input,
            });
        } else {
            toast({
                title: '更改失敗',
                description: '用戶名稱已被使用',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            throw new Error('用戶名稱已被使用');
        }
    };
    const navigate = useNavigate();
    const { mutate, isPending, isError } = useMutation({
        mutationFn: onSubmit,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['userData'] });
            navigate('/settings');
        },
    });

    return (
        <form onSubmit={(e) => mutate(e)}>
            <FormControl
                className="flex flex-col gap-4 items-center"
                isInvalid={isInvalid}
            >
                <VisuallyHidden>
                    <FormLabel>{translationMap.username}</FormLabel>
                </VisuallyHidden>
                <div className="w-full">
                    <Input
                        type="text"
                        value={input}
                        onChange={onChange}
                        placeholder={userData?.username}
                        isDisabled={isPending || isUserDataLoading}
                    />
                    <FormHelperText>暱稱會用作你的主頁地址</FormHelperText>
                    {isInvalid && input.length > 0 && (
                        <FormErrorMessage className="flex flex-col items-start ml-2">
                            字串必須符合：
                            <ul>
                                <li>介於6至30字節</li>
                                <li>大小楷英文字母 A-Z a-z</li>
                                <li>特殊符號 _ -</li>
                            </ul>
                        </FormErrorMessage>
                    )}
                    {isError && (
                        <FormErrorMessage>
                            網絡錯誤，請重新發送
                        </FormErrorMessage>
                    )}
                </div>
                <SubmitButton
                    isDisabled={!userRef || !input || isInvalid || isPending}
                />
            </FormControl>
        </form>
    );
}

function SettingsUsernameEdit() {
    const auth = getAuth();
    const [user] = useAuthState(auth);
    if (!user) return <Spinner />;
    return <SettingsUsernameEditContent user={user} />;
}
