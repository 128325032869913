import { ConfigProvider } from 'antd';
import { useOutlet } from 'react-router-dom';

import AppHeader from './components/AppHeader';
import landingImage from './images/landing-image.jpg';
import './index.css';

function App() {
    const outlet = useOutlet();

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#3C5659',
                        colorPrimaryHover: '#D4D9D2',
                        colorPrimaryActive: '#0D0D0D',
                    },
                    Menu: {
                        horizontalItemHoverColor: '#3C5659',
                        horizontalItemSelectedColor: '#3C5659',
                    },
                },
            }}
        >
            <div className="App flex flex-col h-screen">
                <AppHeader />
                <main className="flex bg-white items-start w-full h-full">
                    {outlet || (
                        <img
                            src={landingImage}
                            alt="chinese art painting"
                            className="w-full max-w-screen-lg"
                        />
                    )}
                </main>
            </div>
        </ConfigProvider>
    );
}

export default App;
