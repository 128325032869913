import { UserOutlined } from '@ant-design/icons';
import {
    Avatar,
    Button,
    Divider,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@chakra-ui/react';
import { Button as AntdButton, Menu, MenuProps } from 'antd';
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function UserMenu({ email, signOut }: { email: string; signOut: () => void }) {
    return (
        <ul className="flex flex-col gap-5 m-5">
            <li className="flex flex-col items-center w-full gap-2">
                <span>{email}</span>
                <Divider />
            </li>
            <li className="flex flex-col w-full">
                <Button className="flex" variant="secondary" border="none">
                    <Link className="flex-grow" to="/settings">
                        設定
                    </Link>
                </Button>
            </li>
            <li className="flex flex-col w-full gap-2">
                <Divider />
                <Button variant="secondary" onClick={signOut}>
                    登出
                </Button>
            </li>
        </ul>
    );
}

export function NavBar() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [current, setCurrent] = useState('');
    const auth = getAuth();
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        const page = pathname.split('/')[1];
        setCurrent(page);
    }, [pathname]);

    const items: MenuProps['items'] = [
        {
            label: <Link to="/about">關於</Link>,
            key: 'about',
        },
        {
            label: <Link to="/gallery">作品</Link>,
            key: 'gallery',
        },
    ];

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
    };

    const signOut = () => {
        auth.signOut().then(() => navigate('/'));
    };

    return (
        <>
            <Menu
                mode="horizontal"
                items={items}
                onClick={onClick}
                selectedKeys={[current]}
                className="grow"
            />
            {user ? (
                <div className="flex items-center gap-4">
                    <AntdButton
                        icon={<UserOutlined />}
                        shape="circle"
                        size="large"
                    />
                    <Popover>
                        <PopoverTrigger>
                            <button className="user-avatar">
                                <Avatar name={user.displayName ?? undefined} />
                            </button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverContent>
                                <UserMenu
                                    email={user.email ?? ''}
                                    signOut={signOut}
                                />
                            </PopoverContent>
                        </PopoverContent>
                    </Popover>
                </div>
            ) : (
                <Link to="/login">
                    <Button variant="primary" isLoading={loading}>
                        注冊/登入
                    </Button>
                </Link>
            )}
        </>
    );
}
