import React from 'react';

export default function ErrorPage() {
    return (
        <div
            id="error-page"
            className="flex flex-col h-full w-full items-center justify-center"
        >
            <h1>Oops!</h1>
            <p>Sorry, this page does not exist.</p>
        </div>
    );
}
