/** https://firebase.google.com/docs/web/setup */
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig: FirebaseOptions = {
    apiKey: 'AIzaSyBWtyRSc3swujKJFxX9rG9MFAb2uRaCcAQ',
    authDomain: 'catherineng-68b95.firebaseapp.com',
    projectId: 'catherineng-68b95',
    storageBucket: 'catherineng-68b95.appspot.com',
    messagingSenderId: '419047572317',
    appId: '1:419047572317:web:8305b0e9fe9b5a06a8deb4',
    measurementId: 'G-E6PD80S33Y',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
