import { Button, Card, Divider, Skeleton, Spinner } from '@chakra-ui/react';
import { User, getAuth } from 'firebase/auth';
import { doc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Link, Outlet } from 'react-router-dom';

import { db } from '../../firebase';
import { GoogleIcon } from '../Login/ProviderIcons';
import { translationMap } from './utils';

function ActionButton({ label, to }: { label: string; to: string }) {
    return (
        <Link to={to}>
            <Button className="w-full" variant="outline">
                {label}
            </Button>
        </Link>
    );
}

function UnitRow({
    isLoaded,
    label,
    content,
    action,
}: {
    isLoaded: boolean;
    label: string | JSX.Element;
    content: string | JSX.Element;
    action?: JSX.Element;
}) {
    return (
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 items-center w-full">
            <h3 className="break-word sm:w-16">{label}</h3>
            <div className="grow text-center sm:text-left">
                <Skeleton isLoaded={isLoaded} className="w-52">
                    {content}
                </Skeleton>
            </div>
            {isLoaded && <div className="w-full sm:max-w-fit">{action}</div>}
        </div>
    );
}

function SettingsAuthUser({ user }: { user: User }) {
    const [userData, isUserDataLoading] = useDocumentData(
        doc(db, 'Users', user.uid)
    );

    return (
        <div className="flex flex-col max-w-5xl gap-10 w-full">
            <section className="flex flex-col gap-4">
                <h3 className="font-bold pl-4">基本信息</h3>
                <Card className="flex flex-col gap-8 items-start px-4 py-8">
                    <UnitRow
                        isLoaded={!isUserDataLoading}
                        label={translationMap.name}
                        content={
                            <span className="font-bold">
                                {userData?.displayName ?? 'Loading...'}
                            </span>
                        }
                        action={
                            <ActionButton label="修改" to="/settings/name" />
                        }
                    />
                    <Divider color="var(--chakra-colors-gray-200)" />
                    <UnitRow
                        isLoaded={!!user}
                        label={translationMap.email}
                        content={
                            <span className="font-bold">{user.email}</span>
                        }
                    />
                    <Divider color="var(--chakra-colors-gray-200)" />
                    <UnitRow
                        isLoaded={!isUserDataLoading}
                        label={translationMap.username}
                        content={
                            <span className="font-bold">
                                {userData?.username ?? 'Loading...'}
                            </span>
                        }
                        action={
                            <ActionButton
                                label="修改"
                                to="/settings/username"
                            />
                        }
                    />
                </Card>
            </section>
            <section className="flex flex-col gap-4">
                <h3 className="font-bold pl-4">已連接第三方賬戶</h3>
                <Card className="flex flex-col gap-8 items-start p-4">
                    <UnitRow
                        isLoaded={!!user}
                        label={<GoogleIcon />}
                        content={<span className="font-bold">Google</span>}
                    />
                </Card>
            </section>
            <Button colorScheme="red" className="w-fit">
                刪除賬號
            </Button>
        </div>
    );
}

export function SettingsIndex() {
    const auth = getAuth();
    const [user, isAuthLoading] = useAuthState(auth);

    if (isAuthLoading || !user) return <Spinner />;
    return <SettingsAuthUser user={user} />;
}

export default function Settings() {
    return (
        <div className="flex flex-col w-full h-full p-10 bg-ss1 bg-opacity-100 items-center">
            <Outlet />
        </div>
    );
}
