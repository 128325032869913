import { LogoutOutlined } from '@ant-design/icons';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

export function NavBarCollapsed() {
    const navigate = useNavigate();

    const auth = getAuth();
    const signOut = () => {
        auth.signOut().then(() => navigate('/'));
    };
    const [user] = useAuthState(auth);

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon />}
                variant="outline"
            />
            <MenuList>
                <MenuGroup>
                    <MenuItem as="a" href="/about">
                        關於
                    </MenuItem>
                    <MenuItem as="a" href="/gallery">
                        作品
                    </MenuItem>
                </MenuGroup>
                <MenuDivider />
                {user ? (
                    <MenuGroup title={user.email ?? undefined}>
                        <MenuItem as="a" href="/settings">
                            設定
                        </MenuItem>
                        <MenuItem as="button" onClick={signOut}>
                            <span>登出</span>
                            <LogoutOutlined className="ml-2" />
                        </MenuItem>
                    </MenuGroup>
                ) : (
                    <MenuGroup>
                        <MenuItem as="a" href="/login">
                            注冊/登入
                        </MenuItem>
                    </MenuGroup>
                )}
            </MenuList>
        </Menu>
    );
}
